<script>
import RoundedContainer from "@/components/RoundedContainer.vue";
import Tag from "@/components/Tag.vue";
import MainButton from "@/components/helpers/MainButton.vue";
import declensionWord from "@/package/helpers/declensio-word.helpers";

export default {
  name: "SectionRegions",
  components: { MainButton, RoundedContainer, Tag },

  props: {
    list: {
      type: Array,
      default: () => [],
    },

    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isShowAllRegions: false,
    };
  },

  computed: {
    regionsKeys() {
      return Object.keys(this.data.regions);
    },

    titleMoreButton() {
      if (this.isShowAllRegions) return "Свернуть";

      return `Показать еще ${this.pluralTitleBtn}`;
    },

    isShowButtonMoreRegions() {
      const count = this.regionsKeys.length;

      return count > 3;
    },

    showerItems() {
      if (this.isShowButtonMoreRegions && !this.isShowAllRegions) {
        return this.data.regions.slice(0, 3);
      }

      return this.data.regions;
    },

    pluralTitleBtn() {
      const number = this.data.regions.length;

      if (number > 3) {
        const newNumber = number - 3;
        const plural = declensionWord(
          newNumber,
          "областей",
          "область",
          "области"
        );

        return `${newNumber} ${plural}`;
      }

      return `${number} область`;
    },
  },

  methods: {},

  mounted() {
    if (this.data.services.length <= 1) this.isShowAllRegions = true;
  },
};
</script>

<template>
  <RoundedContainer class="section-regions">
    <h3 class="section-regions__title">Регионы действия услуг</h3>

    <ul class="section-regions__list">
      <li
        v-for="(item, index) in showerItems"
        :key="index"
        class="section-regions__item item"
        :class="`section-regions__item_map`"
      >
        <span class="item__title">{{ item.region }}</span>

        <div class="item__row-list">
          <Tag v-if="item.all" title="Все города" />

          <template v-else>
            <Tag
              v-for="(subItem, index) in item.cities"
              :key="index"
              :title="subItem"
            />
          </template>
        </div>
      </li>

      <li
        v-if="isShowButtonMoreRegions"
        class="section-regions__item item_no-offset"
      >
        <MainButton
          :title="titleMoreButton"
          :color="'blue'"
          @click="isShowAllRegions = !isShowAllRegions"
        />
      </li>
    </ul>
  </RoundedContainer>
</template>

<style lang="scss" scoped>
.section-regions {
  $size-image: 20px;

  display: flex;
  flex-direction: column;
  gap: $space-l;

  &__title {
    @include title-3;

    color: $dark-primary;
  }

  &__list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0;
    padding: 0;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: $space-s;
    padding-left: calc($space-m + $size-image);

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: $size-image;
      aspect-ratio: 1;
      display: inline-block;
    }

    &_map {
      &:before {
        background-image: url("../../assets/icons/map_pin.svg");
      }
    }
  }
}

.item {
  &_no-offset {
    padding: 0;
  }

  &__title {
    @include body-1-bold;
  }

  &__row-list {
    display: flex;
    gap: $space-xs;
    flex-wrap: wrap;
  }
}
</style>

<script>
import RoundedContainer from "@/components/RoundedContainer.vue";

export default {
  name: "SectionContacts",
  components: { RoundedContainer },

  props: {
    list: {
      type: Array,
      default: () => [],
    },

    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    formatPhoneNumber() {
      const NumReg = new RegExp(/(\d{1,3})(\d{3})(\d{3})(\d{2})(\d{2})/);
      const NumTmp = "$1 ($2) $3-$4-$5";

      return this.data.phone.replace(NumReg, NumTmp);
    },
  },

  methods: {},
};
</script>

<template>
  <RoundedContainer class="section-contacts">
    <h3 class="section-contacts__title">Контакты</h3>

    <ul class="section-contacts__list">
      <li
        class="section-contacts__item item"
        :class="`section-contacts__item_phone`"
      >
        <span class="item__title">Телефон</span>

        <a class="item__link" :href="`tel:${data.phone}`">{{
          formatPhoneNumber
        }}</a>
      </li>

      <li
        class="section-contacts__item item"
        :class="`section-contacts__item_mail`"
      >
        <span class="item__title">Почта</span>

        <a class="item__link" :href="`mailto:${data.email}`">{{
          data.email
        }}</a>
      </li>
    </ul>
  </RoundedContainer>
</template>

<style lang="scss" scoped>
.section-contacts {
  $size-image: 20px;

  display: flex;
  flex-direction: column;
  gap: $space-l;

  &__title {
    @include title-3;

    color: $dark-primary;
  }

  &__list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 0;
    padding: 0;
  }

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: $space-s;
    padding-left: calc($space-m + $size-image);

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: $size-image;
      aspect-ratio: 1;
      display: inline-block;
    }

    > a {
      color: $blue;
      text-decoration: none;
    }

    &_phone {
      &:before {
        background-image: url("../../assets/icons/phone-fill.svg");
      }
    }

    &_mail {
      &:before {
        background-image: url("../../assets/icons/mail-fill.svg");
      }
    }
  }
}

.item {
  &_no-offset {
    padding: 0;
  }

  &__title {
    @include body-1-bold;
  }

  &__link {
    @include body-1;
  }

  &__row-list {
    display: flex;
    gap: $space-xs;
    flex-wrap: wrap;
  }
}
</style>

<script>
import ProfileCard from "@/components/ProfileCard.vue";
import RoundedContainer from "@/components/RoundedContainer.vue";
import MainButton from "@/components/helpers/MainButton.vue";
import {
  validateCurrentInterface,
  isMyProfile,
  CURRENT_SERVICE_SUPPORT_TSP,
} from "@/package/const/service-tsp";
import { da } from "date-fns/locale";

export default {
  name: "SectionProfile",
  components: { MainButton, ProfileCard, RoundedContainer },

  props: {
    currentInterface: {
      type: String,
      validator: (value) => validateCurrentInterface(value),
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      unorderedList: [
        "Расширил клиентскую базу на 500 новых клиентов",
        "Провел 150 полных сопровождений клиента",
        "Опыт в партнерском бизнесе более 10 лет",
      ],
      CURRENT_SERVICE_SUPPORT_TSP,
    };
  },

  computed: {
    da() {
      return da;
    },
    checkProfile() {
      return isMyProfile(this.currentInterface);
    },

    infoInterface() {
      return this.CURRENT_SERVICE_SUPPORT_TSP[this.currentInterface]
        .buttonServicePage;
    },
  },

  methods: {},
};
</script>

<template>
  <RoundedContainer class="section-profile">
    <ProfileCard
      :name="data.name"
      :tariff="data.tariff"
      :avatar="data.avatar"
    />

    <!--    <UnorderedList :list="unorderedList" marker="star" />-->
    <div class="cases" v-html="data.cases"></div>

    <div v-if="checkProfile" class="section-profile__box-btn">
      <MainButton
        class="section-profile__button"
        :title="infoInterface.title"
        color="gold"
        @click="infoInterface.callback"
      />
    </div>
  </RoundedContainer>
</template>

<style lang="scss" scoped>
.section-profile {
  display: flex;
  flex-direction: column;
  gap: $space-l;

  &__button {
    width: fit-content;
    padding-left: $space-l;
    padding-right: $space-l;

    @media (max-width: 1023px) {
      //display: none;
      width: 100%;
    }
  }

  &__box-btn {
    width: 100%;

    @media (max-width: 1023px) {
      position: absolute;
      bottom: 0;
      left: 0;
      background: $background-white;
      padding: $space-s;
      z-index: 2;
    }
  }
}

.cases {
  @include body-1;
  color: $dark-primary;

  &::v-deep {
    ul,
    ol {
      list-style-type: none;
      padding: 0;
      margin: 0;

      > li {
        position: relative;
        padding-left: $space-xl;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        &::before {
          content: "";
          background-image: url("../../assets/icons/marker/star_100.svg");
          display: inline-block;
          width: 20px;
          height: 20px;
          left: 0;
          position: absolute;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: "ProfileCard",
  components: {},
  props: {
    name: {
      type: String,
      required: true,
    },

    tariff: {
      type: Object,
      required: true,
    },

    background: {
      type: Boolean,
      default: false,
    },

    size: {
      type: String,
      validator: (value) => ["l", "m"].includes(value),
      default: "l",
    },

    avatar: {
      type: String,
      default: "",
    },
  },

  data() {
    return {};
  },

  computed: {
    iconClassTariff() {
      //icon-vip_d
      //icon-premium_d
      return `icon-${this.tariff.code}`;
    },

    userImage() {
      if (this.avatar) return this.avatar;

      return require("../assets/images/user_no_img.svg");
    },
  },

  methods: {},
};
</script>

<template>
  <div
    class="profile-card"
    :class="[
      { 'profile-card_background': background },
      `profile-card_size-${size}`,
    ]"
  >
    <img class="profile-card__image" :src="userImage" alt="logo profile" />

    <div class="profile-card__box">
      <span class="profile-card__name">{{ name }}</span>

      <div class="profile-card__tariff-box">
        <span :class="iconClassTariff" />

        <span>{{ tariff.name }}</span>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.profile-card {
  display: flex;
  align-items: center;
  gap: $space-m;

  &__image {
    width: 56px;
    height: 56px;
    overflow: hidden;
    border-radius: 50%;
    flex-shrink: 0;
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: $space-xs;

    & > span {
      @include title-3;

      color: $dark-primary;
      word-break: break-word;
    }
  }

  &__tariff-box {
    display: flex;
    align-items: center;
    gap: $space-xs;

    & > span:first-child {
      width: 16px;
      height: 16px;
      background: $dark-sixth;
    }

    & > span:last-child {
      @include text-2;

      color: $dark-third;
    }
  }

  &_background {
    padding: $space-l;
    border-radius: $space-m;
    background: $background-grey;
  }

  &_size-m {
    > .profile-card {
      &__image {
        width: 48px;
        height: 48px;

        @media (max-width: 1023px) {
          width: 40px;
          height: 40px;
        }
      }

      &__box {
        > span {
          @include body-1-bold;
        }
      }
    }

    &.profile-card_background {
      @media (max-width: 1023px) {
        padding: $space-m;
      }
    }
  }
}
</style>

<script>
import ProfileCard from "@/components/ProfileCard.vue";
import MainButton from "@/components/helpers/MainButton.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import MainLoader from "@/components/helpers/MainLoader.vue";

export default {
  name: "OrderCurrentService",
  components: { MainLoader, MainButton, ProfileCard },
  props: {},

  data() {
    return {
      unorderedList: [
        "Полное сопровождение сделки",
        "Консультации с экспертами",
        "Доступ к эксклюзивным ресурсам",
        "Связь с экспертом 24/7",
      ],

      isLoadingBtn: false,
      isShowPopper: false,
    };
  },

  computed: {
    ...mapState({
      currentConsultant: (state) => state.transactionSupport.currentConsultant,
      isLoadingCurrentConsultant: (state) =>
        state.transactionSupport.isLoadingCurrentConsultant,
      previewTariffPrice: (state) => state.tariff.previewTariffPrice,
    }),

    ...mapGetters({
      getUserDistributorAccount: "auth/getUserDistributorAccount",
    }),

    selectedService() {
      if (!this.currentConsultant) return null;

      const serviceId = this.$route.query?.service;

      return this.currentConsultant.services.find(
        (item) => item.id === +serviceId
      );
    },

    isDisabledButtonBuy() {
      const certificatesAmount =
        this.previewTariffPrice?.certificates?.reduce((total, currentValue) => {
          return total + +currentValue.amount;
        }, 0) ?? 0;

      return (
        certificatesAmount + this.getUserDistributorAccount?.summary_active <
        this.selectedService.price
      );
    },
  },

  methods: {
    ...mapActions({
      sendRequestService: "transactionSupport/sendRequestService",
    }),

    showPopper() {
      const certificatesAmount =
        this.previewTariffPrice?.certificates?.reduce((total, currentValue) => {
          return total + +currentValue.amount;
        }, 0) ?? 0;

      if (
        this.getUserDistributorAccount?.summary_active + certificatesAmount <
        this.selectedService.price
      ) {
        this.isShowPopper = true;
      }
    },

    handleClickOrder() {
      this.isLoadingBtn = true;

      this.sendRequestService({
        consultant_id: this.currentConsultant.id,
        service_id: this.selectedService.id,
      })
        .then((res) => {
          /**
           * Дорогой читатель
           * Я рад что ты дошёл так глубоко и я ценю твоё стремление
           * Познать все загадки вселенной, но на это не смотри
           * Хоть оно и подчёркивается - оно работает как нужно
           * Роут не зарегистрирован с параметрами
           * Так и нужно, чтобы на страницу мы зашли только один раз
           */
          this.$router.push({
            name: "CompleteOrderService",
            params: { isAllowedPage: true, idOrder: res.data.data.id },
          });
        })
        .finally(() => {
          this.isLoadingBtn = false;
        });
    },

    transformNumberDischarge(number) {
      return number.toLocaleString();
    },
  },
};
</script>

<template>
  <section class="order-current-service">
    <MainLoader v-if="isLoadingCurrentConsultant" />

    <template v-else>
      <div class="order-current-service__content">
        <h2>Запись к консультанту</h2>

        <ProfileCard
          class="order-current-service__profile-card"
          :name="currentConsultant.name"
          :tariff="currentConsultant.tariff"
          :avatar="currentConsultant.avatar"
          size="m"
          background
        />

        <section class="order-current-service__info-service">
          <h3>{{ selectedService.name }}</h3>

          <div class="description" v-html="selectedService.description" />

          <span>
            <span>Итого к оплате:</span>
            <span>
              {{ transformNumberDischarge(selectedService.price) }} RC
            </span>
          </span>
        </section>
      </div>

      <div class="order-current-service__footer">
        <VDropdown
          popper-class="tariffs-buy__dropdown"
          class="order-current-service__dropdown"
          :triggers="[]"
          :distance="12"
          :placement="'auto'"
          :shown.sync="isShowPopper"
          @mouseenter.native="showPopper"
        >
          <MainButton
            class="order-current-service__btn-buy"
            title="Записаться"
            color="gold"
            :disabled="isDisabledButtonBuy"
            :loading="isLoadingBtn"
            @click="handleClickOrder"
          />

          <template #popper>
            <div class="tariffs-buy__popper">
              <h4>Недостаточно средств</h4>
              <p>
                На дистрибьюторском счете недостаточно средств, необходимо
                пополнить счет или добавить сертификат(-ы)
              </p>

              <div class="tariffs-buy__popper-buttons">
                <MainButton
                  :title="'Пополнить баланс'"
                  :color="'gold'"
                  @click="$router.push({ name: 'Balance' })"
                />
              </div>
            </div>
          </template>
        </VDropdown>

        <p>
          <!--eslint-disable-next-line-->
        Нажимая «Записаться», вы&nbsp;соглашаетесь с&nbsp;<a href="/docs/ПРАВИЛА_ОКАЗАНИЯ_УСЛУГ_ПО_ПРОДВИЖЕНИЮ.pdf" target="_blank">правилами раздела «Сопровождение сделок с&nbsp;ТСП»</a>
        </p>
      </div>
    </template>
  </section>
</template>

<style lang="scss" scoped>
.order-current-service {
  color: $dark-primary;
  display: flex;
  flex-direction: column;
  gap: $space-xl;
  max-width: 560px;
  width: 100%;

  @media (max-width: 1023px) {
    display: grid;
    grid-template-rows: 1fr min-content;
    max-width: none;
    height: 100%;
    padding-bottom: $space-l;
  }

  &__profile-card {
    @media (max-width: 1023px) {
      padding: $space-m;
    }

    &::v-deep {
      @media (max-width: 1023px) {
        .profile-card__image {
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: $space-xl;

    @media (max-width: 1023px) {
      gap: $space-l;
    }

    > h2 {
      @include title-3;

      color: inherit;
    }
  }

  &__list {
    &::v-deep {
      li:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }

  &__info-service {
    display: flex;
    flex-direction: column;
    gap: $space-m;

    @media (max-width: 1023px) {
      gap: 10px;
    }

    > h3 {
      color: inherit;
      word-break: break-word;
    }

    > span {
      @include body-1-bold;

      color: inherit;

      > span:first-child {
        margin-right: $space-s;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: $space-xl;

    @media (max-width: 1023px) {
      text-align: center;
      gap: $space-m;
    }

    > button {
      width: fit-content;
      padding-left: $space-l;
      padding-right: $space-l;

      @media (max-width: 1023px) {
        width: 100%;
      }
    }

    > p {
      @include text-2;
      color: inherit;

      > a {
        color: $blue;
        text-decoration: none;
      }
    }
  }

  &__btn-buy {
    width: fit-content;
    padding-left: $space-l;
    padding-right: $space-l;

    @media (max-width: 1023px) {
      width: 100%;
    }
  }

  &__dropdown {
    width: fit-content;

    @media (max-width: 1023px) {
      width: 100%;
    }
  }
}

.description {
  color: $dark-primary;
  @include body-1;

  &::v-deep {
    ul,
    ol {
      list-style-type: none;
      padding: 0;
      margin: 0;

      > li {
        display: flex;
        align-items: center;
        padding: 0;
        gap: $space-xs;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        &::before {
          content: "";
          height: 4px;
          display: inline-block;
          aspect-ratio: 1;
          background: $dark-primary;
          border-radius: 50%;
          margin: 0 $space-s;
        }
      }
    }
  }
}
</style>

<script>
export default {
  name: "Tag",

  props: {
    title: {
      type: String,
      defaults: "",
    },

    size: {
      type: String,
      validator: (value) => ["m", "l"].includes(value),
      default: "m",
    },

    color: {
      type: String,
      validator: (value) => ["grey", "yellow"].includes(value),
      default: "grey",
    },

    colorInline: {
      type: String,
      default: "",
    },

    caption: {
      type: [String, Number],
      default: "",
    },
  },

  computed: {
    styleRoot() {
      if (this.colorInline) {
        return {
          "background-color": this.colorInline,
        };
      }

      return {};
    },
  },

  data() {
    return {};
  },
};
</script>

<template>
  <div
    v-if="title"
    class="tag"
    :class="[`tag_size-${size}`, `tag_color-${color}`]"
    :style="styleRoot"
  >
    <p class="tag__title">
      {{ title }} <span class="tag__caption">{{ caption }}</span>
    </p>
    <!--    <span class="tag__title">{{ title }}</span>-->
    <!--    <span class="tag__caption">{{ caption }}</span>-->
  </div>
</template>

<style lang="scss" scoped>
.tag {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: fit-content;
  padding: 0 6px;
  border-radius: $space-s;
  gap: $space-xs;
  white-space: nowrap;

  &__title {
    @include text-2;

    color: $dark-primary;
  }

  &__caption {
    @include caption-1;

    color: $dark-fifth;
  }

  &_size {
    &-l {
      height: 32px;
      padding: 0 8px;

      & > span {
        @include text-2;
      }
    }
  }

  &_color {
    &-grey {
      background-color: $background-grey;
    }

    &-yellow {
      background-color: $services-taxi;
    }
  }
}
</style>

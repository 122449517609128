<script>
export default {
  name: "RadioBox",

  model: {
    prop: "modelValue",
    event: "change",
  },

  props: {
    modelValue: { default: "" },
    value: {
      type: [String, Number],
      required: true,
    },

    name: {
      type: String,
      required: true,
    },

    id: {
      type: [String, Number],
      required: true,
    },

    title: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isChecked() {
      if (!this.disabled) return this.modelValue === this.value;

      return false;
    },
  },
};
</script>

<template>
  <div class="radio-box" :class="{ 'radio-box_disabled': disabled }">
    <input
      :id="id"
      class="radio-box__radio"
      type="radio"
      :name="name"
      :value="value"
      :checked="isChecked"
      :disabled="disabled"
      @change="$emit('change', $event.target.value)"
    />

    <div class="radio-box__radio-area">
      <div class="radio-box__radio-trap">
        <div />

        <span>{{ title }}</span>
      </div>

      <slot />
    </div>

    <label :for="id" />
  </div>
</template>

<style lang="scss" scoped>
.radio-box {
  $time-animation: 0.3s;

  position: relative;

  &__radio-area {
    position: relative;
    padding: $space-l;
    border-radius: $space-m;
    border: 1px solid $outline-light;
    transition: border $time-animation;
    display: flex;
    flex-direction: column;
    gap: $space-m; // Это свойство можно поменять через v-deep
  }

  &__radio-trap {
    display: flex;
    gap: $space-s;
    align-items: center;

    & > div {
      position: relative;
      height: 16px;
      aspect-ratio: 1;
      background: $background-grey;
      border: 1px solid $light-sixth;
      border-radius: 50%;

      transition: border $time-animation;

      &:before {
        content: "";
        height: 0;
        aspect-ratio: 1;
        position: absolute;
        background: transparent;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        opacity: 0;
        transition-property: height, width, background, opacity;
        transition-duration: $time-animation;
      }
    }

    & > span {
      @include body-1-bold;

      color: $dark-primary;
      word-break: break-word;
    }
  }

  & > label {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
  }

  &__radio {
    display: none;

    &:checked + .radio-box__radio-area {
      border: 1px solid $dark-second;

      > .radio-box__radio-trap {
        > div {
          border: 1px solid $dark-second;

          &:before {
            content: "";
            height: 10px;
            background: $dark-second;
            opacity: 1;
          }
        }
      }
    }
  }
}

.radio-box_disabled {
  .radio-box__radio-trap {
    > div {
      display: none;
    }
  }

  > label {
    cursor: auto;
  }
}
</style>

<script>
import RoundedContainer from "@/components/RoundedContainer.vue";
import MainForm from "@/components/helpers/MainForm.vue";
import RadioBox from "@/components/RadioBox.vue";
import {
  validateCurrentInterface,
  isMyProfile,
  CURRENT_SERVICE_SUPPORT_TSP,
} from "@/package/const/service-tsp";
import MainButton from "@/components/helpers/MainButton.vue";
import { mapActions } from "vuex";

export default {
  name: "SectionService",
  components: {
    MainButton,
    RoundedContainer,
    MainForm,
    RadioBox,
  },

  props: {
    currentInterface: {
      type: String,
      validator: (value) => validateCurrentInterface(value),
      required: true,
    },

    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      service: "",
      disabledRadio: false,
    };
  },

  computed: {
    isForOtherUser() {
      return !isMyProfile(this.currentInterface);
    },

    infoInterface() {
      return CURRENT_SERVICE_SUPPORT_TSP[this.currentInterface]
        .buttonServicePage;
    },
  },

  methods: {
    handleSubmit() {
      this.$router.push({ query: { service: this.service } });
    },

    transformNumberDischarge(number) {
      return number.toLocaleString();
    },
  },

  created() {
    this.service = this.data.services[0]?.id;
  },

  watch: {
    currentInterface: {
      immediate: true,
      handler() {
        if (isMyProfile(this.currentInterface)) {
          this.disabledRadio = true;
          this.service = "";
        } else {
          this.disabledRadio = false;
          this.service = "Консультация";
        }
      },
    },
  },
};
</script>

<template>
  <RoundedContainer class="section-service">
    <h3 class="section-service__title">Услуги и цены</h3>

    <MainForm class="section-service__form" @submit="handleSubmit">
      <RadioBox
        v-for="item in data.services"
        :id="item.id"
        :key="item.id"
        v-model="service"
        class="radio"
        name="www"
        :title="item.name"
        :value="item.id"
        :disabled="disabledRadio"
      >
        <div class="service-html" v-html="item.description"></div>

        <div class="radio__price">
          {{ transformNumberDischarge(item.price) }} RC
        </div>
      </RadioBox>

      <div v-if="isForOtherUser" class="form__btn-box">
        <MainButton
          type="submit"
          class="form__btn"
          :title="infoInterface.title"
          color="gold"
        />
      </div>
    </MainForm>
  </RoundedContainer>
</template>

<style lang="scss" scoped>
.section-service {
  color: $dark-primary;
  display: flex;
  flex-direction: column;
  gap: $space-l;

  @media (max-width: 1023px) {
    padding-bottom: $space-xxxl;
  }

  &__title {
    @include title-3;
  }

  &__form::v-deep {
    > .main-form__wrapper {
      display: flex;
      flex-direction: column;
      gap: $space-m;
    }
  }

  &__btn {
    @media (max-width: 1023px) {
      display: none;
    }
  }
}

.radio {
  &__price {
    @include body-1-bold;

    height: 30px;
    width: fit-content;
    display: flex;
    align-items: center;
    padding: 0 $space-s;
    background: $dark-primary;
    border-radius: $space-s;
    color: $light-primary;
  }
}

.form {
  &__btn {
    width: fit-content;
    padding-left: $space-l;
    padding-right: $space-l;

    @media (max-width: 1023px) {
      width: 100%;
    }
  }

  &__btn-box {
    margin-top: $space-s;

    @media (max-width: 1023px) {
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: $space-s;
      background: $light-primary;
    }
  }
}

.service-html {
  color: $dark-primary;
  @include body-1;

  &::v-deep {
    ul,
    ol {
      list-style-type: none;
      padding: 0;
      margin: 0;

      > li {
        display: flex;
        align-items: center;
        padding: 0;
        gap: $space-xs;

        &:not(:last-child) {
          margin-bottom: 12px;
        }

        &::before {
          content: "";
          height: 4px;
          display: inline-block;
          aspect-ratio: 1;
          background: $dark-primary;
          border-radius: 50%;
          margin: 0 $space-s;
        }
      }
    }
  }
}
</style>

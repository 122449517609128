<script>
import RoundedContainer from "@/components/RoundedContainer.vue";
import MainButton from "@/components/helpers/MainButton.vue";

export default {
  name: "EmptyServices",
  components: { MainButton, RoundedContainer },

  data() {
    return {};
  },

  computed: {},

  methods: {},
};
</script>

<template>
  <RoundedContainer class="section-empty-service">
    <div class="section-empty-service__content">
      <img src="../../assets/icons/illustration.svg" alt="images" />

      <div class="section-empty-service__box">
        <h3>Стать консультантом</h3>

        <p>
          Готовы стать консультантом? Заполните и&nbsp;отправьте
          на&nbsp;рассмотрение заявку для создания собственной карточки
          консультанта.
        </p>
      </div>
    </div>

    <div class="section-empty-service__footer">
      <MainButton title="Заполнить заявку" color="gold" />

      <p>
        Нажимая «Заполнить заявку», вы&nbsp;соглашаетесь
        <a
          href="/docs/ПРАВИЛА_ОКАЗАНИЯ_УСЛУГ_ПО_ПРОДВИЖЕНИЮ.pdf"
          target="_blank"
        >
          с&nbsp;правилами раздела «Сопровождение сделок с&nbsp;ТСП»
        </a>
      </p>
    </div>
  </RoundedContainer>
</template>
<style lang="scss" scoped>
.section-empty-service {
  display: flex;
  flex-direction: column;
  gap: $space-l;
  color: $dark-primary;
  //illustration

  @media (max-width: 1023px) {
    height: 100%;
    display: grid;
    grid-template-rows: 1fr min-content;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: $space-l;

    @media (max-width: 1023px) {
      gap: $space-m;
      align-items: center;
      margin-top: 74px;
    }

    > img {
      height: 96px;
      width: 96px;

      @media (max-width: 1023px) {
        height: 120px;
        width: 120px;
      }
    }
  }

  &__box {
    display: flex;
    flex-direction: column;
    gap: $space-m;

    @media (max-width: 1023px) {
      gap: $space-s;
      align-items: center;
      text-align: center;
    }

    > h3 {
      @include title-3;

      color: inherit;
    }

    > p {
      @include body-1;

      color: inherit;
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    gap: $space-l;

    @media (max-width: 1023px) {
      gap: $space-m;
    }

    > p {
      @include text-2;

      text-align: left;
      color: inherit;

      @media (max-width: 1023px) {
        text-align: center;
      }

      > a {
        color: $blue;
        text-decoration: none;
      }
    }
  }
}
</style>

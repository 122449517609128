<script>
import ButtonRedirect from "@/components/helpers/ButtonRedirect.vue";
import SectionEmptyServices from "@/components/transaction-support/SectionEmptyServices.vue";
import SectionProfile from "@/components/transaction-support/SectionProfile.vue";
import SectionContacts from "@/components/transaction-support/SectionContacts.vue";
import SectionServices from "@/components/transaction-support/SectionServices.vue";
import OrderCurrentService from "@/components/transaction-support/OrderCurrentService.vue";
import SectionRegions from "@/components/transaction-support/SectionRegions.vue";
import { CURRENT_SERVICE_SUPPORT_TSP } from "@/package/const/service-tsp";
import { mapActions, mapState } from "vuex";
import MainLoader from "@/components/helpers/MainLoader.vue";

export default {
  name: "ServicesTsp",
  components: {
    SectionProfile,
    ButtonRedirect,
    SectionEmptyServices,
    SectionContacts,
    SectionServices,
    OrderCurrentService,
    MainLoader,
    SectionRegions,
  },

  data() {
    return {
      currentInterface: "forOther",
      CURRENT_SERVICE_SUPPORT_TSP,
    };
  },

  computed: {
    ...mapState({
      currentConsultant: (state) => state.transactionSupport.currentConsultant,
      isLoadingCurrentConsultant: (state) =>
        state.transactionSupport.isLoadingCurrentConsultant,
      user: (state) => state.auth.user,
    }),

    selectedService() {
      const service = this.$route.query.service;

      if (service) return service;

      return null;
    },

    isExistProfile() {
      return this.$route.params.id !== "empty-profile";
    },
  },

  methods: {
    ...mapActions({
      getCurrentConsultant: "transactionSupport/getCurrentConsultant",
    }),

    goBackButton() {
      if (this.selectedService) {
        this.$router.go(-1);
        return;
      }

      this.$router.push({ name: "TransactionSupportTsp", replace: true });
    },
  },

  created() {
    const id = this.$route.params?.id;

    if (id) {
      this.currentInterface = "forOther";
    } else {
      this.currentInterface = "forMe";
    }
  },

  mounted() {
    const id = this.$route.params?.id;

    if (id) {
      if (+id === this.user.consultant_id) {
        this.currentInterface = "forMe";
      } else {
        this.currentInterface = "forOther";
      }

      this.getCurrentConsultant(id);
    }
  },
};
</script>

<template>
  <div
    class="services-tsp default-content-padding"
    :class="{ 'services-tsp_order': selectedService }"
  >
    <div class="services-tsp__header">
      <ButtonRedirect
        class="services-tsp__back"
        text="Назад"
        @click="goBackButton"
      />
    </div>

    <div class="services-tsp__container">
      <MainLoader v-if="isLoadingCurrentConsultant" />

      <template v-else>
        <template v-if="selectedService">
          <OrderCurrentService />
        </template>

        <template v-else>
          <template v-if="isExistProfile">
            <SectionProfile
              :current-interface="currentInterface"
              :data="currentConsultant"
            />
            <SectionContacts :data="currentConsultant" />
            <SectionRegions :data="currentConsultant" />
            <SectionServices
              :data="currentConsultant"
              :current-interface="currentInterface"
            />
          </template>

          <SectionEmptyServices v-else />
        </template>
      </template>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.services-tsp {
  padding: $space-xxl $space-xxl $space-xxxl;
  //display: flex;
  //flex-direction: column;
  //gap: $space-l;
  max-width: 680px;
  width: 100%;
  //height: calc(100% - 64px);

  &_order {
    background-color: $background-white;
    max-width: none;

    > .services-tsp__container {
      @media (max-width: 1023px) {
        padding: 0 $space-l;
      }

      @media (max-width: 375px) {
        padding: 0 $space-m;
      }
    }
  }

  @media (max-width: 1023px) {
    //background-color: $background-white;
    max-width: none;
    padding: 0;
    //padding: $space-xxl $space-l 0;
    //height: calc(100% - 34px);
    height: 100%;
  }

  @media (max-width: 768px) {
    //padding: $space-xxl $space-l 0;
  }

  @media (max-width: 375px) {
    //padding: $space-xxl $space-m 0;
  }

  &__back {
    //background-color: $background-white;

    //@media (max-width: 1023px) {
    //  padding: $space-xxl $space-l 0;
    //}
    //
    //@media (max-width: 375px) {
    //  padding: $space-xxl $space-m 0;
    //}
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: $space-l;
    //height: inherit;

    @media (max-width: 1023px) {
      height: calc(100% - 75.6px);
      gap: $space-s;

      > div:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      > div:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  &__header {
    margin-bottom: $space-xxxl;

    @media (max-width: 1023px) {
      background-color: $background-white;
      margin-bottom: 0;
      width: 100%;

      padding: $space-xxl $space-l $space-s;
    }

    @media (max-width: 375px) {
      padding: $space-xxl $space-m $space-s;
    }
  }

  //&__btn-box {
  //  display: none;
  //  position: absolute;
  //  z-index: 2;
  //  bottom: 0;
  //  left: 0;
  //  width: 100%;
  //  padding: $space-s;
  //  background: $light-primary;
  //
  //  @media (max-width: 1023px) {
  //    display: block;
  //  }
  //}
}
</style>

<script>
export default {
  name: "RoundedContainer",
  components: {},

  data() {
    return {};
  },

  computed: {},

  methods: {},
};
</script>

<template>
  <div class="rounded-container">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.rounded-container {
  padding: 40px;
  border-radius: $space-m;
  background: $light-primary;

  @media (max-width: 1023px) {
    padding: $space-l;
  }
}
</style>

const CURRENT_INTERFACE_VALUE = ["forMe", "forOther"];

const isMyProfile = (value) => value === CURRENT_INTERFACE_VALUE[0];

const validateCurrentInterface = (value) =>
  CURRENT_INTERFACE_VALUE.includes(value);

const CURRENT_SERVICE_SUPPORT_TSP = {};
CURRENT_SERVICE_SUPPORT_TSP[CURRENT_INTERFACE_VALUE[0]] = {
  buttonServicePage: {
    title: "Редактировать карточку",
    callback: (...rest) => {
      console.log("Редактировать карточку", rest);
    },
  },
};

CURRENT_SERVICE_SUPPORT_TSP[CURRENT_INTERFACE_VALUE[1]] = {
  buttonServicePage: {
    title: "Записаться",
    callback: (...rest) => {
      console.log("Записаться", rest);
    },
  },
};

const STATUS_OPERATIONS = {
  new: {
    text: "В обработке",
    color: "#F8F8F8",
  },

  in_work: {
    text: "В работе",
    color: "#EEECFA",
  },

  canceled: {
    text: "Отменена",
    color: "#FDF0E8",
  },

  awaited_approve: {
    text: "Ожидает подтверждения",
    color: "#FFF0C7",
  },

  awaited_cancel: {
    text: "Ожидает отмены",
    color: "#FFF0C7",
  },

  awaited_check: {
    text: "Ожидает проверки",
    color: "#FFF0C7",
  },

  finished: {
    text: "Завершена",
    color: "#EEFAD1",
  },
};

export {
  CURRENT_SERVICE_SUPPORT_TSP,
  CURRENT_INTERFACE_VALUE,
  STATUS_OPERATIONS,
  validateCurrentInterface,
  isMyProfile,
};
